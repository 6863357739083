html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bgstyle {
  background-size: 1200% 1200%;
  -webkit-animation: BackgroundAnimation 70s ease infinite;
  -moz-animation: BackgroundAnimation 70s ease infinite;
  -o-animation: BackgroundAnimation 70s ease infinite;
  animation: BackgroundAnimation 70s ease infinite;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}
.navbar-white {
  background-color: rgba(234, 236, 239, 0.81);
  transition: background-color 0.2s;
}
.brand {
  color: #363636 !important;
}
.nav-link {
  transition: color, 0.2s;
}
.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: blue;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.skills-tabs {
  flex-grow: 1;
}
.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}
.progress-bar-animation {
  @extend .progress;
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}

#projects {
  background-color: #fff;
  color: #272341;
  padding-top: 4rem;

  .project-wrapper {
    margin-bottom: 15rem;

    .row {
      margin-bottom: 8rem;
    }

    &__text {
      text-align: left;

      &-title {
        font-weight: bold;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
      }

      & p > a {
        color: #00cdac;
      }
    }

    &__image {
      width: 90%;
      margin: 0 auto;

      & .thumbnail {
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        transition: all 0.2s ease-out;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
        transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.5s box-shadow,
          0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
      }
    }
  }
}

.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 20px;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
}

.cta-btn--hero {
  background-image: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
  @media (min-width: 768px) {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
  border-image-slice: 1;
  cursor: pointer;

  &::after {
    background-image: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
  }

  &:hover {
    -webkit-text-fill-color: #fff;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

.project-modal {
  @media (min-width: 768px) {
    width:70vw;
    max-width:70vw;
  }
}

.footer-paralax {
  padding: 4rem 0 0 0;
}

.contact-mf {
  margin-top: 4rem;
}

#sendmessage {
  color: #0078ff;
  border: 1px solid #0078ff;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
  display: block;
}

.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

.contact-mf .box-pl2 {
  @media (min-width: 767px) {
    margin-top: 3rem;
    padding-left: 0rem;
  }
}